<template>
  <Transition name="fade">
    <div v-if="isActive" tabindex="1" class="modal is-active">
      <div class="modal-background" @click="onClickClose"></div>
      <div class="animation-content">
        <div class="card-modal-v2">
          <div class="btn-close" @click="onClickClose"></div>
          <div class="card-content-v2">
            <div class="card-content-v2-header">
              <div class="card-content-v2-header-icon"></div>
              <div class="card-content-v2-header-message">
                <template v-if="isGS1">GolTipster</template><template v-else>Premium</template>
              </div>
              <div class="card-content-v2-header-buttoncont">
                $ 99 MXN <span style="font-weight: 500; font-weight: normal; ">al mes</span>
              </div>
            </div>
            <div class="card-content-v2-content">
              <div class="card-content-v2-content-info">
                Para tener acceso a todos los partidos, necesitas crear tu
                <span
                  style="font-weight: bold; color: #00a5ff; text-decoration: underline; cursor: pointer;"
                  @click="onClickCreateAccount"
                  >Cuenta <template v-if="isGS1">GolTipster</template><template v-else>Premium</template></span
                >.
              </div>
              <div class="card-content-v2-content-info-opt">
                ¿Ya tienes una?
                <span style="color: #00a5ff; text-decoration: underline; cursor: pointer;" @click="onClickLogin"
                  >Hacer login</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { clickCrearCuentaPremium, clickLoginBlackPopUp, clickBtnBlackPopupCerrar } from '@/utils/analytics.js';
export default {
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  watch: {
    isOpened: {
      immediate: true,
      handler: function(newValue) {
        this.isActive = newValue;
      },
    },
    isActive(value) {
      window.parent.postMessage({ message: 'modalShown', isShown: value }, '*');
      if (!value) this.$emit('closeTooltip');
    },
    displayPremiumMessageV2() {
      this.isActive = this.displayPremiumMessageV2;
    },
  },
  mounted() {
    this.isActive = this.isOpened;
  },
  computed: {
    ...mapGetters(['displayPremiumMessageV2']),
    ...mapGetters('general', ['getSectionAnalytics', 'getPreviewSectionAnalytics']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats', 'isGS1']),
    section() {
      return this.$route.meta.title;
    },
  },
  methods: {
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapMutations(['SET_PREMIUM_MESSAGE_V2']),
    ...mapMutations('general', ['setSectionAnalytics', 'setPreviewSectionAnalytics']),
    onClickLogin() {
      let sectionPopUp = this.getSectionAnalytics.includes('Banner_Home') ? '' : this.getSectionAnalytics;
      clickLoginBlackPopUp({
        section: sectionPopUp,
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
      });
      this.SET_AUTH_COMPONENT('Login');
      this.SET_PREMIUM_MESSAGE_V2(false);
    },
    onClickCreateAccount() {
      this.setSectionAnalytics(
        `${this.getPreviewSectionAnalytics ? this.getPreviewSectionAnalytics + '_' : ''}${this.getSectionAnalytics}`,
      );
      clickCrearCuentaPremium({
        is_premium: this.getPremiumAccount,
        section: this.section,
        golstats: this.getIsGolstats,
        banner: this.getSectionAnalytics,
      });
      this.SET_AUTH_COMPONENT('SelectPlan');
      this.SET_PREMIUM_MESSAGE_V2(false);
    },
    onClickClose() {
      let sectionPopUp = this.getSectionAnalytics.includes('Banner_Home') ? '' : this.getSectionAnalytics;
      clickBtnBlackPopupCerrar({
        section: sectionPopUp,
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
      });
      this.setSectionAnalytics('Banner_Home');
      this.SET_PREMIUM_MESSAGE_V2(false);
    },
  },
};
</script>

<style scoped>
.card-modal-v2 {
  position: relative;
  margin: 0 auto;
  width: 496px;
  background-color: rgba(255, 255, 255);
  color: #ffffff;
  border-radius: 24px;
  box-shadow: 0 9px 19px 0 rgb(0 0 0 / 27%);
}
.card-content-v2 {
  text-align: center;
  height: 380px;
  width: 496px;
  border-radius: 24px;
  font-family: 'Avenir-Pro-Medium';
  font-size: 17px;
}
.card-content-v2-header {
  width: 496px;
  height: 189px;
  border-radius: 24px;
  background: rgb(25, 114, 197);
  background-image: linear-gradient(to right top, #1972c5, #0086d6, #009ae5, #00aef3, #00c2ff);
}
.card-content-v2-header-icon {
  width: 92px;
  height: 92px;
  background-image: url('/images/crown.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
}
.card-content-v2-header-message {
  width: 189px;
  height: 30px;
  /* background: rgb(1, 36, 39); */
  margin: 0 auto;
  font-size: 38.6px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  font-family: 'Roboto-Bold';
}
.card-content-v2-header-buttoncont {
  width: 159px;
  height: 28px;
  /* background: rgb(129, 0, 71); */
  margin: 0 auto;
  margin-top: 12px;
  border-radius: 6px;
  font-family: 'Heebo';
  border: solid 1px #fff;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.card-content-v2-content {
  width: 496px;
  height: 191px;
  /* background: greenyellow; */
  display: inline-block;
}
.card-content-v2-content-info {
  width: 404px;
  height: 72px;
  /* background: #1972c5; */
  margin: 0 auto;
  margin-top: 32px;
  font-family: 'Heebo';
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: #4f5360;
}
.card-content-v2-content-info-opt {
  width: 303px;
  height: 24px;
  /* background: #114e01; */
  margin: 0 auto;
  margin-top: 24px;
  font-family: 'Heebo';
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: #4f5360;
}
.btn-close {
  position: absolute;
  width: 36.3px;
  height: 36.3px;
  top: -11px;
  right: -11px;
  cursor: pointer;
  border-radius: 36.9px;
  background-color: #313131;
  background-image: url('/images/iconclosegray.png');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center center;
}
.btn-close:hover {
  opacity: 0.9;
  cursor: pointer;
}
.link-btn {
  text-decoration: underline;
  cursor: pointer;
}
.modal-close {
  position: absolute;
  top: 10px;
  margin-right: -12px;
}
.modal-background {
  background-color: rgba(0, 0, 0, 0.7);
}

.amount {
  font-size: 20px;
}

.animation-content {
  width: 550px;
  padding: 2rem;
}
@media screen and (max-width: 563px) {
  .card-modal-v2 {
    position: relative;
    width: 90%;

    background-color: rgba(255, 255, 255);
    color: #ffffff;
    border-radius: 24px;
    box-shadow: 0 9px 19px 0 rgb(0 0 0 / 27%);
  }
  .card-content-v2 {
    text-align: center;
    height: 380px;
    width: 100%;
    border-radius: 24px;
    font-family: 'Avenir-Pro-Medium';
    font-size: 17px;
  }
  .card-content-v2-content {
    width: 100%;
    height: 191px;
    display: inline-block;
  }
  .card-content-v2-header {
    width: 100%;
    height: 189px;
    border-radius: 24px;
  }
  .animation-content {
    width: 95%;
    padding: 0px;
  }
  .card-content-v2-content-info {
    width: 94%;
    height: 72px;
    margin: 0 auto;
    margin-top: 32px;
    font-family: 'Heebo';
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
    color: #4f5360;
  }
  .card-content-v2-content-info-opt {
    width: 92%;
    height: 24px;
    margin: 0 auto;
    margin-top: 24px;
    font-family: 'Heebo';
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: center;
    color: #4f5360;
  }
}
</style>
